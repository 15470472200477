import * as React from "react";
import CardsSection from "../components/Cards";
import ContactSection from "../components/Contact";
import DownloadSection from "../components/Download";
import FeaturesSection from "../components/Features";
import Footer from "../components/Footer";
import LangingHero from "../components/LandingHero";
import Logo from "../components/logo";
import PeopleSection from "../components/PeopleSection";
import ShoppingSection from "../components/Shopping";
import { inject } from '@vercel/analytics';

inject();


export function Head() {
  return <title>Tapka</title>;
}

const ComingSoonIndex = () => {
  return (
    <>
      <LangingHero />
      <CardsSection />
      <PeopleSection />
      <FeaturesSection />
      <ShoppingSection />
      <ContactSection />
      <DownloadSection />
      <Footer />
    </>
  );
};

export default ComingSoonIndex;
