import * as React from "react";
import { DownloadIcon } from "../icons";
import Container from "./common/Container";
import styled from "styled-components";
import { AppStoreIcon, PlayStoreIcon } from "../icons/responsive";

const FeaturesSectionStyled = styled.section`
  padding: 38px 0px;
  @media only screen and (max-width: 860px) {
    padding: 38px 0px 0px 0px;
  }
`;
const FeaturesList = styled.div`
  display: flex;

  @media only screen and (max-width: 1200px) {
    padding: 38px 24px;
  }
  p {
    font-size: 14px;
    margin: 0;
  }

  .number {
    font-size: 22px;
    font-weight: 700;
    position: absolute;
    left: 20px;
    top: 20px;
  }
  @media only screen and (max-width: 860px) {
    flex-direction: column;

    .first {
      margin: 0;
    }
    .second {
      margin: 0;
    }
    .third {
      margin: 0;
    }
  }
`;

const FeaturesListItem = styled.div`
  flex: 1;
  border: 1px solid var(--color-border-gray);
  border-radius: 20px;
  display: flex;
  justify-content: center;
  flex-direction: column;
  align-items: center;
  padding: 76px 60px 48px 60px;
  position: relative;

  @media only screen and (max-width: 860px) {
    margin-bottom: 24px !important;
    padding: 48px 24px 38px 24px;
  }
  h2 {
    font-weight: 700;
    font-size: 28px;
    line-height: 38px;
    text-align: center;
    color: #343434;
  }
  p {
    color: #343434;
    text-align: center;
    strong {
      color: var(--primary);
    }
  }
`;

const FeaturesListItemNumber = styled.div``;
const DownloadButton = styled.div`
  /* display: flex; */
  /* justify-content: center; */
  padding: 72px 0;
  a {
    text-decoration: none;
    max-width: 355px;
    display: block;
    margin: 0 auto;
  }

  .download-button {
    background-color: var(--text-darker);
    font-weight: 700;
    color: var(--text-light);
    text-decoration: none;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 74px;
    /* width: 100%; */

    font-size: 24px;
    border-radius: 18px;
    svg {
      padding-left: 24px;
    }
  }
  @media only screen and (max-width: 860px) {
    padding: 72px 24px;

    .download-button {
      font-size: 22px;
      /* width: 100%; */
      /* max-width: 355px; */
    }
  }
`;

export const ListIcon = styled.div<{ small?: boolean }>`
  .top {
    width: 41px;
    height: 10px;
    background-color: #000;
    margin-bottom: 5px;
    @media only screen and (max-width: 860px) {
      width: 34px;
      height: 8px;
    }
  }
  .middle {
    width: 52px;
    height: 30px;
    @media only screen and (max-width: 860px) {
      width: 44px;
      height: 25px;
    }
  }
  ${({ small = false }) =>
    small &&
    `
    padding: 0 5px;
    .top {
      width: 27px;
      height: 7px;
    }
    .middle {
      width: 35px;
      height: 20px;
    }
    `}
`;

const DonwloadContainer = styled.div`
  background: linear-gradient(170.57deg, #8216d5 19.58%, #5b0ab0 93.77%);
  margin-top: 100px;
  margin-bottom: 60px;
  border-radius: 32px;
  color: #fff;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  padding: 60px 0;
  p {
    font-size: 16;
    max-width: 380px;
    padding: 0 16px;
  }
  h1 {
    font-size: 42px;
    padding: 0 16px;
  }

  @media only screen and (max-width: 1100px) {
    border-radius: 0px;
  }
`;

export const DownloadButtonsContainer = styled.div`
  display: flex;
  gap: 14px;
  @media only screen and (max-width: 346px) {
    flex-direction: column;
  }
`;
export const StoreDownloadButton = styled.a`
  height: 56px;
  max-width: 166px;
  padding: 0 20px;
  background-color: #343434;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  &:hover {
    background-color: #4c4c4c;
  }

  @media only screen and (max-width: 380px) {
    padding: 0 10px;
  }
  @media only screen and (max-width: 346px) {
    padding: 0 20px;
  }
`;
const FeaturesSection = () => {
  return (
    <FeaturesSectionStyled id="funkcionalnosti">
      <Container>
        <FeaturesList>
          <FeaturesListItem className="first">
            <div className="number">01</div>
            <ListIcon>
              <div className="top"></div>
              <div className="middle bg-purple"></div>
            </ListIcon>
            <h2>Oslobodi svoj novčanik</h2>
            <p>
              Nema više preturanja po torbi i novčaniku dok čekaš red na kasi.
              Uz <strong>Tapka</strong> aplikaciju, otvori omiljenu karticu sa jednim
              klikom.
            </p>
          </FeaturesListItem>
          <FeaturesListItem className="second">
            <div className="number">02</div>
            <ListIcon>
              <div className="top"></div>
              <div className="middle bg-orange"></div>
            </ListIcon>
            <h2>Kupuj pametno i uštedi novac</h2>
            <p>
              Iz jedne aplikacije upravljaj svim svojim benefitima i ostvari
              posebne pogodnosti.
            </p>
          </FeaturesListItem>
          <FeaturesListItem className="third">
            <div className="number">03</div>
            <ListIcon>
              <div className="top"></div>
              <div className="middle bg-yellow"></div>
            </ListIcon>
            <h2>Kartice su uvjek uz tebe</h2>
            <p>
              Često zaboravite popust karticu kod kuće? Uz{" "}
              <strong>Tapka</strong> aplikaciju, popusti su uvjek uz tebe.
            </p>
          </FeaturesListItem>
          <span></span>
        </FeaturesList>

        <DonwloadContainer id="store-links">
          <h1>Preuzmi aplikaciju</h1>
          <p>
            Izaberi platformu koju koristiš i preuzmi mobilnu aplikaciju na svoj
            mobilni telefon!{" "}
          </p>
          <DownloadButtonsContainer>
            <StoreDownloadButton
              href="https://play.google.com/store/apps/details?id=me.konfidi.wallet"
              target="_blank"
            >
              <PlayStoreIcon />
            </StoreDownloadButton>
            <StoreDownloadButton
              href="https://apps.apple.com/us/app/konfidi-kartice-na-jedan-klik/id1633509284"
              target="_blank"
            >
              <AppStoreIcon />
            </StoreDownloadButton>
          </DownloadButtonsContainer>
        </DonwloadContainer>
      </Container>
    </FeaturesSectionStyled>
  );
};

export default FeaturesSection;
