import * as React from "react";
import Header from "./Header";
//@ts-ignore
import PhoneImg from "../images/phone-main.png";
//@ts-ignore
import LogoImg from "../images/bg-logo.png";
//@ts-ignore
import LandingImg from "../images/landing.png";
//@ts-ignore
import LandingImgWithBg from "../images/landing-with-bg.png";

import AppStore from "./AppStore";
import PlayStore from "./PlayStore";
import Container from "./common/Container";

import styled from "styled-components";
import { AppStoreIcon, PlayStoreIcon } from "../icons/responsive";
import { DownloadButtonsContainer, StoreDownloadButton } from "./Features";

const Landing = styled.section`
  min-height: 700px;
  display: flex;
  flex-direction: column;
  background: linear-gradient(170.57deg, #8216d5 19.58%, #5b0ab0 93.77%);
`;

const LandingLeft = styled.div`
  flex: 1;
  display: flex;
  align-items: flex-end;
  img {
    position: relative;
    bottom: -42px;
    padding-left: 100px;
  }
  @media only screen and (max-width: 1200px) {
    justify-content: center;
    img {
      width: 90%;
      max-width: 520px;
      padding-left: 0px;
    }
  }
`;

const LandingRight = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  @media only screen and (max-width: 1200px) {
    align-items: center;
    p {
      padding: 0 24px;
    }
    h1 {
      padding: 0 24px;
    }
    padding: 62px 0px 0px 0px;
  }
  .text-light {
    /* max-width: 500px; */
    padding-right: 50px;
    @media only screen and (max-width: 1200px) {
      padding-right: 0px;
    }
  }
`;

const StoreIcon = styled.div<{ left?: boolean }>`
  height: 50px;
  width: 166px;
  background-color: #343434;
  border-radius: 5px;
  display: flex;
  justify-content: center;
  align-items: center;

  margin-right: ${(props) => (props.left ? "24px" : "0px")};

  @media only screen and (max-width: 510px) {
    flex: 1;
  }
  @media only screen and (max-width: 410px) {
    margin-right: ${(props) => (props.left ? "16px" : "0px")};
    width: 150px;
  }

  @media only screen and (max-width: 364px) {
    width: 140px;
    svg {
      transform: scale(0.9);
    }
  }
`;

const StoreContainer = styled.div`
  margin-top: 18px;
  display: flex;
`;
const LangingHero = () => {
  return (
    <Landing id="pocetna">
      <Header />
      <Container full>
        <div className="flex-column">
          <LandingRight>
            <h1 className="text-light">Tvoj mobilni novčanik.</h1>
            <p className="text-light">
              Oslobodi se plastičnih kartica i trguj sa lakoćom! Uz{" "}
              <strong>Tapka</strong> mobilnu aplikaciju, sve tvoje popust
              kartice su na jedan klik.
            </p>
            {/* <StoreContainer>
              <StoreIcon left>
                <AppStoreIcon />
              </StoreIcon>
              <StoreIcon>
                <PlayStoreIcon />
              </StoreIcon>
            </StoreContainer> */}

            <DownloadButtonsContainer>
              <StoreDownloadButton
                href="https://play.google.com/store/apps/details?id=me.konfidi.wallet"
                target="_blank"
              >
                <PlayStoreIcon />
              </StoreDownloadButton>
              <StoreDownloadButton
                href="https://apps.apple.com/us/app/konfidi-kartice-na-jedan-klik/id1633509284"
                target="_blank"
              >
                <AppStoreIcon />
              </StoreDownloadButton>
            </DownloadButtonsContainer>
          </LandingRight>
          <LandingLeft>
            <img src={LandingImg} alt="" />
          </LandingLeft>
        </div>
      </Container>
    </Landing>
  );
};

export default LangingHero;
