import * as React from "react";

const LogoFull = (props: any) => (
  <svg
    width={89}
    height={24}
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}
  >
   <path
      fill="#fff"
      fillOpacity={0.65}
      d="M0 7.712c0-.675.474-1.496 1.059-1.833L10.99.145c.585-.338 1.059-.064 1.059.61v7.722c0 .675-.474 1.496-1.059 1.834L1.06 16.045C.474 16.382 0 16.109 0 15.433V7.713Z"
    />
    <path fill="#8216D5" d="m0 9.633 12.049-6.956v1.027L0 10.66V9.633Z" />
    <path
      fill="#fff"
      d="M0 7.712c0-.675.474-.949 1.059-.611l9.931 5.734c.585.337 1.059 1.158 1.059 1.833v7.722c0 .675-.474.949-1.059.611L1.06 17.267C.474 16.93 0 16.109 0 15.434V7.712Z"
    />
    <path fill="#8216D5" d="M0 10.443 12.049 17.4v1.026L0 11.47v-1.027Z" />
    <path
      fill="#fff"
      d="M28.475 9.237h-4.823v2.995c0 2.009.38 2.52 2.178 2.52.934 0 1.452 0 2.645-.176v2.431c-.985.176-2.109.264-3.474.264-2.628 0-4.322-1.004-4.322-3.294v-4.74H18v-2.52h2.68V4.569l2.972-.863v3.013h4.823v2.52Zm8.04-2.696c4.252 0 6.672 1.392 6.707 5.321v5.233h-2.973V15.81a13.46 13.46 0 0 1-6.189 1.462c-2.852 0-4.632-.933-4.632-3.277 0-2.695 2.904-3.541 6.136-3.541 1.746 0 3.354.282 4.685.74 0-1.973-2.49-2.132-3.872-2.132-1.331 0-2.956.123-5.048.758l-1.054-2.203c2.074-.687 4.114-1.075 6.24-1.075Zm3.734 7.33v-.81c-1.038-.335-2.438-.635-4.322-.635-2.212 0-3.543.388-3.543 1.445 0 1.092.985 1.18 2.523 1.18 1.608 0 3.82-.51 5.342-1.18Zm12.329-7.33c3.284 0 6.067 1.41 6.067 5.374 0 3.965-2.783 5.356-6.067 5.356-2.196 0-3.959-.599-5.065-1.78V21.5H44.54V6.718h2.973V8.32c1.106-1.163 2.87-1.78 5.065-1.78Zm-.986 8.21c2.645 0 4.097-.792 4.097-2.836 0-2.061-1.452-2.854-4.097-2.854-2.644 0-4.079.793-4.079 2.854 0 2.044 1.435 2.837 4.08 2.837ZM75.27 6.719l-6.603 4.246 6.603 6.131h-4.114l-4.875-4.528-3.474 2.132v2.396H59.85V3h2.956v8.387l7.416-4.67h5.047Zm6.94-.177c4.253 0 6.673 1.392 6.708 5.321v5.233h-2.973V15.81a13.46 13.46 0 0 1-6.189 1.462c-2.852 0-4.632-.933-4.632-3.277 0-2.695 2.904-3.541 6.136-3.541 1.746 0 3.354.282 4.685.74 0-1.973-2.49-2.132-3.872-2.132-1.331 0-2.956.123-5.048.758L75.97 7.616c2.074-.687 4.114-1.075 6.24-1.075Zm3.735 7.33v-.81c-1.038-.335-2.438-.635-4.322-.635-2.212 0-3.543.388-3.543 1.445 0 1.092.985 1.18 2.523 1.18 1.608 0 3.82-.51 5.342-1.18Z"
    />
  </svg>
);

export default LogoFull;
